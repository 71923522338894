.reporting {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.reporting__menu {
    width: 100%;
    height: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    overflow-x: auto;

    &-filter > span {
        display: inline-block;
        margin-bottom: 4px;
        font-weight: bold;
    }

    &-filter input {
        width: 140px !important;
    }

    &-select-item {
        padding-right: 20px;
    }

    &-select-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .bp5-multi-select {
        width: 240px;
    }

    & .bp5-tag-input-values {
        flex-wrap: nowrap;
        overflow: auto;
    }
}

.reporting__content {
    position: relative;
    width: 100%;
    flex-grow: 1;
    display: flex;

    &-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-white;
    }
}

.reporting__stats {
    width: fit-content;
    height: 100%;
    padding: 20px;

    &-title {
        margin-bottom: 0.25rem;
    }
    &-value {
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
}

.reporting__main {
    height: 100%;
    padding: 20px;
    flex-grow: 1;
}